import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialToolsOfferWall } from '../components/FinancialToolsOfferWall/FinancialToolsOfferWall';
import { Hero } from '../components/InnerPages/Hero';
import SaveMoneyIcon from '../images/SaveMoney';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface IInsuranceMoneyProps {
  location: any;
}

const InsuranceMoney = ({ location }: IInsuranceMoneyProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "103446";
        _clcktt['cIdMobile'] = "3349";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Insurance savings you can't ignore" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<SaveMoneyIcon />}
          title="Insurance savings you can't ignore"
          description="Free, easy, and professional guidance from trusted providers"
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
            <FinancialToolsOfferWall
              items={[
                {
                  title: 'Cheapest Auto Insurance in 2025',
                  link: '/the-cheapest-car-insurance-in-2024-and-how-to-save-more/',
                },
                {
                  title: 'All Auto Insurance Reviews',
                  link: '/tag/auto/',
                },
                {
                  title: 'How To Save On Auto Insurance',
                  link: '/11-factors-affecting-your-car-insurance-rates-and-how-to-lower-it/',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default InsuranceMoney;
